import OperateTimeIcon from "@/assets/images/inventory-center/operate-time-icon.svg"
import WarehouseAgeIcon from "@/assets/images/inventory-center/warehouse-age-icon.svg"
import storageListIcon from "@/assets/images/inventory-center/storage-list-icon.png"
import storageOfGoodsIcon from "@/assets/images/inventory-center/storage-of-goods.svg"
import CargoDeliveryInformationIcon from "@/assets/images/inventory-center/cargo-delivery-information.svg"
import abnormalShipmentsIcon from "@/assets/images/inventory-center/abnormal-shipments.svg"



// 路由meta的title项 必须为 该多语言对应字段名

export default [
    {
        name: 'inventoryCenter',
        path: '/inventoryCenter',
        component: () => import('@/views/inventory-center'),
        children: [
            // {
            //     name: 'goodsAgeing',
            //     path: '/inventoryCenterIndex',
            //     component: () => import('@/views/inventory-center/goods-ageing'),
            //     redirect: '/inventoryCenter/goodsAgeing/index',
            //     meta: {
            //         title: 'goods_ageing',
            //         icon: WarehouseAgeIcon,
            //         isMulti: false,
            //     },
            //     children: [
            //         {
            //             name: 'goodsAgeingIndex',
            //             path: '/inventoryCenter/goodsAgeing/index',
            //             component: () => import('@/views/inventory-center/goods-ageing/goods-ageing-index'),
            //             meta: {
            //                 title: 'goods_ageing',
            //                 isShowing: true
            //             }
            //         }
            //     ]
            // },
            {
                name: 'operateTime',
                path: '/inventoryCenter/operateTime',
                component: () => import('@/views/inventory-center/operate-time'),
                redirect: '/inventoryCenter/operateTime/index',
                meta: {
                    title: 'operate_time',
                    icon: OperateTimeIcon,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'operateTimeIndex',
                        path: '/inventoryCenter/operateTime/index',
                        component: () => import('@/views/inventory-center/operate-time/operate-time-index'),
                        meta: {
                            title: 'operate_time',
                            isShowing: true
                        }
                    }
                ]
            },
            { // 库存列表
                name: 'storageList',
                path: '/inventoryCenter/storageList',
                component: () => import('@/views/inventory-center/storage-list'),
                redirect: '/inventoryCenter/storageList/index',
                meta: {
                    title: 'storage_list',
                    icon: storageListIcon,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'storageListIndex',
                        path: '/inventoryCenter/storageList/index',
                        component: () => import('@/views/inventory-center/storage-list/storage-list-index'),
                        meta: {
                            title: 'storage_list',
                            isShowing: true
                        }
                    }
                ]
            },
            { // 货物仓储
                name: 'storageOfGoods',
                path: '/inventoryCenter/storageOfGoods',
                component: () => import('@/views/inventory-center/storage-of-goods'),
                redirect: '/inventoryCenter/storageOfGoods/index',
                meta: {
                    title: 'storage_of_goods',
                    icon: storageOfGoodsIcon,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'storageOfGoodsIndex',
                        path: '/inventoryCenter/storageOfGoods/index',
                        component: () => import('@/views/inventory-center/storage-of-goods/storage-of-goods-index'),
                        meta: {
                            title: 'storage_of_goods',
                            isShowing: true
                        }
                    },
                    {
                        name: 'storageOfGoodsDetail',
                        path: '/inventoryCenter/storageOfGoods/detail',
                        component: () => import('@/views/inventory-center/storage-of-goods/storage-of-goods-detail'),
                        meta: {
                            title: 'cargo_storage_details',
                            isShowing: true
                        }
                    }
                ]
            },
            { // 货物送仓信息
                name: 'CargoDeliveryInformation',
                path: '/inventoryCenter/CargoDeliveryInformation',
                component: () => import('@/views/inventory-center/cargo-delivery-information'),
                redirect: '/inventoryCenter/CargoDeliveryInformation/index',
                meta: {
                    title: 'cargo_delivery_information',
                    icon: CargoDeliveryInformationIcon,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'CargoDeliveryInformationIndex',
                        path: '/inventoryCenter/CargoDeliveryInformation/index',
                        component: () => import('@/views/inventory-center/cargo-delivery-information/cargo-delivery-information-index'),
                        meta: {
                            title: 'cargo_delivery_information',
                            isShowing: true
                        }
                    }
                ]
            },
            { // 库存异常货件
                name: 'abnormalShipments',
                path: '/inventoryCenter/abnormalShipments',
                component: () => import('@/views/inventory-center/abnormal-shipments'),
                redirect: '/inventoryCenter/abnormalShipments/index',
                meta: {
                    title: 'abnormal_shipments',
                    icon: abnormalShipmentsIcon,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'abnormalShipmentsIndex',
                        path: '/inventoryCenter/abnormalShipments/index',
                        component: () => import('@/views/inventory-center/abnormal-shipments/abnormal-shipments-index'),
                        meta: {
                            title: 'abnormal_shipments',
                            isShowing: true
                        }
                    }
                ]
            },
        ]
    }
]