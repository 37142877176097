import pickingTaskIcon from "@/assets/images/distribution-center/picking-task-icon.svg"
import warehouseManageIcon from "@/assets/images/distribution-center/warehouse-manage-icon.svg"
import warehouseManagingIcon from "@/assets/images/distribution-center/warehouse-managing-icon.svg"
import distributionManageIcon from "@/assets/images/distribution-center/distribution-manage-icon.svg"
import shelvesManageIcon from "@/assets/images/distribution-center/shelves-manage-icon.svg"
import productInformationIcon from "@/assets/images/distribution-center/product-information-icon.svg"
import orderManageIcon from "@/assets/images/distribution-center/order-manage-icon.svg"
import generalSituationIcon from "@/assets/images/sidebar-icon/warehouse-data-icon.svg"
import systemSettingIcon from "@/assets/images/distribution-center/system-setting-icon.svg"
/**
 * @param {Boolean} isMulti 是否有多级导航，仅父路由有
 * @param {Boolean} isShowing 是否显示  
 */
export default [
  {
    name: "distributionCenter",
    path: "/distributionCenter",
    component: () => import("@/views/distribution-center"),
    children: [
      // 概况
      // {
      //   name: "dGeneralSituation", // 概况
      //   path: "/distributionCenter/dGeneralSituation",
      //   component: () => import("@/views/distribution-center/general-situation"),
      //   redirect: "/distributionCenter/dGeneralSituation/index",
      //   meta: {
      //     title: "general_situation",
      //     icon: generalSituationIcon,
      //     isMulti: false,
      //   },
      //   children: [
      //     {
      //       name: "dGeneralSituationIndex", // 概况 
      //       path: "/distributionCenter/dGeneralSituation/index",
      //       component: () => import("@/views/distribution-center/general-situation/general-situation-index"),
      //       meta: {
      //         title: "general_situation",
      //         isShowing: false
      //       }
      //     },
      //   ]
      // },
      // 拣货任务
      {
        name: "dPickingTask",
        path: "/distributionCenterIndex",
        component: () => import("@/views/distribution-center/picking-task"),
        redirect: "/distributionCenter/dPickingTask/pickingList",
        meta: {
          title: "picking_task",
          icon: pickingTaskIcon,
          isMulti: true,
          isShowing: true
        },
        children: [
          // 拣货订单
          {
            name: "pickingList",
            path: "/distributionCenter/dPickingTask/pickingList",
            component: () => import("@/views/distribution-center/picking-task/picking-list"),
            meta: {
              title: "picking_order",
              isShowing: true
            }
          },
          // 拣货任务
          {
            name: "pickingTask",
            path: "/distributionCenter/dPickingTask/pickingTask",
            component: () => import("@/views/distribution-center/picking-task/picking-task"),
            meta: {
              title: "picking_task",
              isShowing: true
            }
          },
          // 拣货出库
          {
            name: "pickingOutwarehouse",
            path: "/distributionCenter/dPickingTask/pickingOutwarehouse",
            component: () => import("@/views/distribution-center/picking-task/picking-outwarehouse"),
            meta: {
              title: "picking_outwarehouse",
              isShowing: true
            }
          },
          // 任务详情
          {
            name: "taskDetail",
            path: "/distributionCenter/dPickingTask/taskDetail",
            component: () => import("@/views/distribution-center/picking-task/task-detail"),
            meta: {
              title: "task_detail",
              isShowing: false,
              activeTitle: 'pickingTask'
            }
          },
        ]
      },
      // 入库管理
      // {
      //   name: "dWarehousingManage",
      //   path: "/distributionCenter/dWarehousingManage",
      //   component: () => import("@/views/distribution-center/warehousing-manage"),
      //   redirect: "/distributionCenter/dWarehousingManage/index",
      //   meta: {
      //     title: "warehousing_manage",
      //     icon: warehouseManagingIcon,
      //     isMulti: false,
      //   },
      //   children: [
      //     // 入库管理
      //     {
      //       name: "dWarehouseManagingIndex",
      //       path: "/distributionCenter/dWarehousingManage/index",
      //       component: () => import("@/views/distribution-center/warehousing-manage/warehousing-manage-index"),
      //       meta: {
      //         title: "warehousing_manage",
      //         isShowing: false
      //       }
      //     },
      //   ]
      // },
      // 配货管理
      {
        name: "dDistributionManage",
        path: "/distributionCenter/dDistributionManage",
        component: () => import("@/views/distribution-center/distribution-manage"),
        redirect: "/distributionCenter/dDistributionManage/index",
        meta: {
          title: "distribution_manage",
          icon: distributionManageIcon,
          isMulti: false,
          isShowing: true
        },
        children: [
          // 配货管理
          {
            name: "dDistributionManageIndex",
            path: "/distributionCenter/dDistributionManage/index",
            component: () => import("@/views/distribution-center/distribution-manage/distribution-manage-index"),
            meta: {
              title: "distribution_manage",
              isShowing: false
            }
          },
        ]
      },
      // 上下架管理
      {
        name: "dShelvesManage",
        path: "/distributionCenter/dShelvesManage",
        component: () => import("@/views/distribution-center/shelves-manage"),
        redirect: "/distributionCenter/dShelvesManage/pullOnShelvesList",
        meta: {
          title: "shelves_manage",
          icon: shelvesManageIcon,
          isMulti: true,
          isShowing: true
        },
        children: [
          // 上架列表
          {
            name: "pullOnShelvesList",
            path: "/distributionCenter/dShelvesManage/pullOnShelvesList",
            component: () => import("@/views/distribution-center/shelves-manage/pull-on-shelves-list"),
            meta: {
              title: "pull_on_shelves_list",
              isShowing: true
            }
          },
          // 下架列表
          {
            name: "pullOffShelvesList",
            path: "/distributionCenter/dShelvesManage/pullOffShelvesList",
            component: () => import("@/views/distribution-center/shelves-manage/pull-off-shelves-list"),
            meta: {
              title: "pull_off_shelves_list",
              isShowing: true
            }
          },
        ]
      },
      // 货物入库
      {
        name: "dGoodsInWarehouse",
        path: "/distributionCenter/dGoodsInWarehouse",
        component: () => import("@/views/distribution-center/goods-in-warehouse"),
        redirect: "/distributionCenter/dGoodsInWarehouse/goodsStock",
        meta: {
          title: "goods_in_warehouse",
          icon: warehouseManageIcon,
          isMulti: true,
          isShowing: true
        },
        children: [
          // 货物库存
          {
            name: "goodsStock",
            path: "/distributionCenter/dGoodsInWarehouse/goodsStock",
            component: () => import("@/views/distribution-center/goods-in-warehouse/goods-stock"),
            meta: {
              title: "goods_stock",
              isShowing: true
            }
          },
          // 货物详情
          {
            name: "goodsStockDetail",
            path: "/distributionCenter/dGoodsInWarehouse/goodsStockDetail",
            component: () => import("@/views/distribution-center/goods-in-warehouse/goods-stock-detail"),
            meta: {
              title: "goods_stock_detail",
              isShowing: false,
              activeTitle: 'goodsStock'
            }
          },
          // 货物sku库存
          {
            name: "goodsSkuStock",
            path: "/distributionCenter/dGoodsInWarehouse/goodsSkuStock",
            component: () => import("@/views/distribution-center/goods-in-warehouse/goods-sku-stock"),
            meta: {
              title: "goods_sku_stock",
              isShowing: true
            }
          },
          // SKU批次库存
          {
            name: "skuBatchStock",
            path: "/distributionCenter/dGoodsInWarehouse/skuBatchStock",
            component: () => import("@/views/distribution-center/goods-in-warehouse/sku-batch-stock"),
            meta: {
              title: "sku_batch_stock",
              isShowing: true
            }
          },
        ]
      },
      // 仓库管理
      {
        name: "dWarehouseManage",
        path: "/distributionCenter/dWarehouseManage",
        component: () => import("@/views/distribution-center/warehouse-manage"),
        redirect: "/distributionCenter/dWarehouseManage/goodsStock",
        meta: {
          title: "warehouse_manage",
          icon: warehouseManageIcon,
          isMulti: true,
          isShowing: true
        },
        children: [
          // sku总库存
          {
            name: "skuStock",
            path: "/distributionCenter/dWarehouseManage/skuStock",
            component: () => import("@/views/distribution-center/warehouse-manage/sku-stock"),
            meta: {
              title: "sku_stock",
              isShowing: true
            }
          },
          // SKU货架库存
          {
            name: "skuShelfStock",
            path: "/distributionCenter/dWarehouseManage/skuShelfStock",
            component: () => import("@/views/distribution-center/warehouse-manage/sku-shelf-stock"),
            meta: {
              title: "sku_shelf_stock",
              isShowing: true
            }
          },
          // 库位管理
          {
            name: "locationManage",
            path: "/distributionCenter/dWarehouseManage/locationManage",
            component: () => import("@/views/distribution-center/warehouse-manage/location-manage"),
            meta: {
              title: "location_manage",
              isShowing: true
            }
          },
          // 容器管理
          {
            name: "containerManage",
            path: "/distributionCenter/dWarehouseManage/containerManage",
            component: () => import("@/views/distribution-center/warehouse-manage/container-manage"),
            meta: {
              title: "container_manage",
              isShowing: true
            }
          },
          // 库存调整
          // {
          //   name: "inventoryAdjustment",
          //   path: "/distributionCenter/dWarehouseManage/inventoryAdjustment",
          //   component: () => import("@/views/distribution-center/warehouse-manage/inventory-adjustment"),
          //   meta: {
          //     title: "inventory_adjustment",
          //     isShowing: true
          //   }
          // },
          // 容器详情
          {
            name: "containerDetail",
            path: "/distributionCenter/dWarehouseManage/containerDetail",
            component: () => import("@/views/distribution-center/warehouse-manage/container-detail"),
            meta: {
              title: "container_detail",
              isShowing: false,
              activeTitle: 'containerManage'
            }
          },
          // 仓库详情
          {
            name: "warehouseDetail",
            path: "/distributionCenter/dWarehouseManage/warehouseDetail",
            component: () => import("@/views/distribution-center/warehouse-manage/warehouse-detail"),
            meta: {
              title: "warehouse_detail",
              isShowing: false,
              activeTitle: 'locationManage'
            }
          },
          // 分区详情
          {
            name: "subareaDetail",
            path: "/distributionCenter/dWarehouseManage/subareaDetail",
            component: () => import("@/views/distribution-center/warehouse-manage/subarea-detail"),
            meta: {
              title: "subarea_detail",
              isShowing: false,
              activeTitle: 'locationManage'
            }
          },
          // 库位详情
          {
            name: "locationDetail",
            path: "/distributionCenter/dWarehouseManage/locationDetail",
            component: () => import("@/views/distribution-center/warehouse-manage/location-detail"),
            meta: {
              title: "location_detail",
              isShowing: false,
              activeTitle: 'locationManage'
            }
          },


        ]
      },
      // 商品信息
      {
        name: "dProductInformation",
        path: "/distributionCenter/dProductInformation",
        component: () => import("@/views/distribution-center/product-information"),
        redirect: "/distributionCenter/dProductInformation/index",
        meta: {
          title: "product_information",
          icon: productInformationIcon,
          isMulti: false,
        },
        children: [
          // 商品信息
          {
            name: "dProductInformationIndex",
            path: "/distributionCenter/dProductInformation/index",
            component: () => import("@/views/distribution-center/product-information/product-information-index"),
            meta: {
              title: "product_information",
              isShowing: false
            }
          },
        ]
      },
      // 订单管理
      {
        name: "dOrderManage",
        path: "/distributionCenter/dOrderManage",
        component: () => import("@/views/distribution-center/order-manage"),
        redirect: "/distributionCenter/dOrderManage/allOrder",
        meta: {
          title: "order_manage",
          icon: orderManageIcon,
          isMulti: true,
          isShowing: true
        },
        children: [
          // 全部订单
          {
            name: "allOrder",
            path: "/distributionCenter/dOrderManage/allOrder",
            component: () => import("@/views/distribution-center/order-manage/all-order"),
            meta: {
              title: "all_order",
              isShowing: true
            }
          },
          // SHEIN订单
          // {
          //   name: "sheinOrder",
          //   path: "/distributionCenter/dOrderManage/sheinOrder",
          //   component: () => import("@/views/distribution-center/order-manage/shein-order"),
          //   meta: {
          //     title: "shein_order",
          //     isShowing: true
          //   }
          // },
        ]
      },
      // 系统设置
      {
        name: "dSystemSetting",
        path: "/distributionCenter/dSystemSetting",
        component: () => import("@/views/distribution-center/system-setting"),
        redirect: "/distributionCenter/dOrderManage/allOrder",
        meta: {
          title: "system_setting",
          icon: systemSettingIcon,
          isMulti: true,
          isShowing: true
        },
        children: [
          // 快递商运费管理
          {
            name: "freightMange",
            path: "/distributionCenter/dSystemSetting/freightMange",
            component: () => import("@/views/distribution-center/system-setting/freight-manage"),
            meta: {
              title: "courier_freight_manage",
              isShowing: true
            }
          },
        ]
      },
    ]
  }
]