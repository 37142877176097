import { onMounted } from "vue";
export default {
  name: "MessageMain",
  props: {
    text: {
      type: String,
      default: ""
    },
    warnActive: {
      type: Boolean,
      default: false
    },
    warnText: {
      type: String,
      default: ""
    },
    operate1Text: {
      type: String,
      default: ""
    },
    operate2Text: {
      type: String,
      default: ""
    },
    colorText: {
      type: String,
      default: "#F91010"
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["operate1", "operate2", "handleClose"],
  setup(props, content) {
    onMounted(() => {});
    function operate1() {
      content.emit("operate1");
    }
    function operate2() {
      content.emit("operate2");
    }
    function handleClose() {
      content.emit("handleClose");
    }
    return {
      operate1,
      operate2,
      handleClose
    };
  }
};