import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
const app = createApp(App)

// 公共组件
import {
    TcPopup,
    TcPopupOperate,
    CommonSelect,
    CommonPagination,
    CommonUpload,
    CommonDownload,
    CommonTable,
    CommonDatePicker,
    CommonRadio,
    CommonSearch,
    CommonTableKey,
    TcTree,
    CommonPicturePreview,
    // CommonMusicErr
} from '@/components'
app.component("TcPopup", TcPopup);
app.component("TcPopupOperate", TcPopupOperate);
app.component("CommonSelect", CommonSelect);
app.component("CommonPagination", CommonPagination);
app.component("CommonDataPicker", CommonDatePicker);
app.component("CommonUpload", CommonUpload);
app.component("CommonDownload", CommonDownload);
app.component("CommonTable", CommonTable);
app.component("CommonRadio", CommonRadio);
app.component("CommonSearch", CommonSearch);
app.component("TcTree", TcTree);
app.component("CommonTableKey", CommonTableKey);
app.component("CommonPicturePreview", CommonPicturePreview);
// app.component("CommonMusicErr", CommonMusicErr)
// 公共样式
import '@/assets/styles/common.scss'

// 路由守卫
import '@/router/permission'

// 引入自定义消息弹窗
import TcMessage from '@/utils/common/tc-message'
app.config.globalProperties.$TcMsg = TcMessage

// element plus
import ElementPlus from 'element-plus'

// 多语言
import i18n from './locales/index'
app.config.globalProperties.$t = i18n.global.t

// 响应式px转rem
import "@/utils/common/pxtorem"

// 引入 echarts文件
import echarts from './utils/common/echarts.js';
app.config.globalProperties.$echarts = echarts;

// 获取缓存数据
import getCache from './utils/common/get-cache'
app.config.globalProperties.$getCache = getCache

// 路由变化/点击，修改侧边导航并存储跳转路径
import sideMenuChange from './utils/common/side-menu-change'
app.config.globalProperties.$sideMenuChange = sideMenuChange

// 行内样式转换rem
function px2rem(px) {
    if (/%/ig.test(px)) {
        return px
    } else {
        return (parseFloat(px) / 100) + 'rem'
    }
}
app.config.globalProperties.$px2rem = px2rem;

// 引入数字计算（精度问题）
import { add } from './utils/common/numeration';
app.config.globalProperties.$add = add;
import { minus } from './utils/common/numeration';
app.config.globalProperties.$minus = minus;
import { mutiply } from './utils/common/numeration';
app.config.globalProperties.$mutiply = mutiply;
import { devide } from './utils/common/numeration';
app.config.globalProperties.$devide = devide;
import { multiAdd } from './utils/common/numeration';
app.config.globalProperties.$multiAdd = multiAdd;

// 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}

// 引入a标签下载方法
import downloadFile from './utils/common/download-file';
app.config.globalProperties.$downloadFile = downloadFile

// 引入文件下载方法
import exportFile from './utils/common/export-file';
app.config.globalProperties.$exportFile = exportFile


// 引入数字判断
import numberOnly from './utils/common/number-only'
app.config.globalProperties.$numberOnly = numberOnly

// 引入范围计算
import calcRange from './utils/common/calc-range'
app.config.globalProperties.$calcRange = calcRange

// 引入文本框字符串转数组方法
import str2arr from './utils/common/strtoarr'
app.config.globalProperties.$str2arr = str2arr

import print from 'vue3-print-nb'

// 按钮权限指令
app.directive('auth', {
    mounted(el, binding) {
        let userBtns = store.state.user.userBtns
        // console.log('按钮权限指令userBtns',userBtns);

        if (!userBtns.includes(binding.value)) {
            el.parentNode.removeChild(el)
        }
    }
})

app.use(store).use(router).use(i18n).use(ElementPlus).use(print).mount('#app')

// 非开发环境不使用打印语句
if (process.env.NODE_ENV !== 'development' && process.env.VUE_APP_BASE_URL == 'https://abroad-api.tclogx.com/') { 
    console.log = function () {}
}
